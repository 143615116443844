@import "../../themes.scss";

.header-container {
  padding: 5px;
  width: 100% !important;

  // ================================ first block =============================
  .stack-container-first {
    width: 100% !important;
    // background-size: 100%;
    // background-position: 0% 50%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;

    .share-des {
      font-size: $font-size-subtitle;
      font-family: $font-khmer-moul;
      color: $primary-color;
      font-weight: 100;
    }
    .data-item {
      margin-top: 10px;
      margin-bottom: 15px;
      .front-icon {
        width: 80%;
      }
      .share-number {
        font-size: 26px;
        font-weight: bold;
      }
      .icon-money {
        width: 30px;
        height: 30px;
        justify-items: center;
        margin-top: 13px;
        margin-bottom: auto;
        margin-left: 15px;
      }
      .each-data {
        margin-left: 30px;
        margin-bottom: 15px;
        .all-sale {
          color: $primary-color;
          padding-top: 5px;
          justify-content: center;
        }
      }
    }
  }

  // ================================ second block =============================
  .stack-container-second {
    background-size: 40%;
    background-position: 95% 80%;
    background-color: #e1505d;
    color: #fff;
    border-radius: 10px;
    // padding: 40px 20px;

    .front-icon {
      width: 80%;
    }
    .share-number {
      font-size: 26px;
      font-weight: bold;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }

  // ================================ third block =============================
  .stack-container-third {
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 100% 50%;
    background-color: #fff;
    // color: #fff;
    border-radius: 10px;
    padding: 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
      font-weight: bold;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }

  // ================================ fourth block =============================
  .stack-container-fourth {
    // background-image: url("../../../Assets/icon_dashboard/Fourth_share.png");
    background-repeat: no-repeat;
    background-size: 28%;
    background-position: 90% 50%;
    background-color: #2a9ada;
    color: #fff;
    border-radius: 10px;
    padding: 40px 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
      font-weight: bold;
    }
    .share-des {
      font-family: $font-siemreap !important;
      letter-spacing: normal !important;
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }
}
