@import "../themes.scss";

.page-container {
  .slash {
    height: 40px;
    width: 4px;
    background-color: $primary-color;
  }

  .page-title {
    font-family: $font-kantumruy;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold !important;
  }

  .page-header {
    height: 40px;
    width: 100%;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }
    .page-title-kh {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy;
      color: black;
      font-size: $font-size-body;
    }
    .breadcrumbs-title-kh {
      font-family: $font-kantumruy;
      color: black;
      font-size: $font-size-body;
    }

    .slash-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold;
    }

    .page-link-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .search-title {
    width: 130px !important;
    margin-top: 8px;
    font-family: $font-bayon;
    color: $primary-color;
    font-size: $font-size-subtitle;
  }

  .header-text {
    font-family: $font-kantumruy;
    color: $primary-color;
    font-size: $font-size-body;
    // margin-bottom: 5px;
  }

  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-kantumruy;
    font-weight: bold;
    color: $primary-color;
    border-color: #fff;

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: $primary-color !important;
      font-family: $font-kantumruy;
      border-radius: 6px !important;
      border-color: #fff;
    }

    .MuiInputLabel-root.Mui-focused {
      color: $primary-color;
      font-family: $font-kantumruy;
      border-radius: 6px;
      border-color: #fff;
    }

    .MuiOutlinedInput-input {
      color: $primary-color;
      font-family: $font-kantumruy;
      border-color: #fff;
    }

    .supplier-detail-box {
      justify-content: space-between;
    }
  }

  .select-role {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-kantumruy;
    font-weight: bold;
    color: $primary-color;

    .MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-kantumruy;
      border-radius: 6px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiInputLabel-root.Mui-focused {
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
      border-color: #fff;
    }
  }

  .btn-create {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 6px 30px;
    height: 100%;
    margin-top: 25px;

    .icon-add {
      width: 18px;
      height: 18px;
    }
  }

  .body-container {
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .table {
      width: 100%;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0px 6px;

      .header-row {
        background-color: #fff;
        padding: 10px 0px;

        .header-title-start {
          border-radius: 6px 0px 0px 6px;
          padding: 10px 10px 10px 20px;
          border: none;
          font-family: $font-kantumruy;
          font-size: $font-size-body;
          color: $primary-color;
          font-weight: bold;
        }

        .header-title {
          padding: 10px 10px 10px 20px;
          border: none;
          font-family: $font-kantumruy;
          font-size: $font-size-body;
          color: $primary-color;
          font-weight: bold;
        }

        .header-title-end {
          border-radius: 0px 6px 6px 0px;
          padding: 10px 10px 10px 0px;
          border: none;
          font-family: $font-kantumruy;
          font-size: $font-size-body;
          // font-weight: bold;
          color: $primary-color;
          .btn-create {
            width: 80px;
            height: 30px;
            font-size: 10px;
            font-family: $font-kantumruy;
            .icon-add-list {
              width: 13px;
              height: 13px;
            }
          }
        }

        .header-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
        }

        .header-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
        }
      }

      .body {
        background-color: #fff;
        box-shadow: none;

        :hover {
          background-color: rgb(239, 246, 253);
        }
        .onclick-student {
          background-color: rgb(239, 246, 253);
        }
        .body-cell-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .body-cell-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .body-row {
          cursor: pointer;
          .body-cell-start {
            border: none;
            font-size: $font-size-body;
            padding: 6px 6px 6px 20px;
            border-radius: 6px 0px 0px 6px;
            .time-schedule {
              font-size: $font-size-body;
              font-family: $font-kantumruy;
              color: $primary-color;
            }
          }
          .body-cell-start-void {
            border: none;
            font-size: $font-size-body;
            padding: 6px 6px 6px 20px;
            border-radius: 6px 0px 0px 6px;
            background-color: rgb(250, 240, 240);
          }

          .body-cell {
            border: none;
            font-size: $font-size-body;
            padding: 6px 6px 6px 20px;
            font-family: $font-kantumruy;

            .user-name {
              font-family: $font-kantumruy !important;
              font-size: $font-size-body !important;
            }

            .project-name {
              font-weight: bold;
              font-size: $font-size-body;
              color: $primary-color;
            }

            .customer-type {
              width: fit-content;
              font-family: $font-kantumruy;
              font-size: 12px;
              background-color: $primary-color;
              padding: 1px 8px;
              border-radius: 2px;
              color: #fff;
              border-radius: 10px;
              margin: 0 auto;
            }

            .subject-name {
              color: $primary-color;
              font-size: 14px;
              text-align: center;
              font-weight: bold;
            }
            .teacher-name {
              color: $primary-color;
              font-size: 14px;
              text-align: center;
            }
            .quotation-status {
              width: 80px;
              text-align: center;
              font-family: $font-kantumruy;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 10px;
              padding: 4px 0px;
              border-radius: 4px;
              color: #fff;
            }
            .invoice-status {
              width: 80px;
              text-align: center;
              font-family: $font-kantumruy;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 10px;
              padding: 4px 0px;
              border-radius: 4px;
              color: #fff;
            }
          }

          .body-cell-void {
            border: none;
            font-size: $font-size-body;
            padding: 6px 6px 6px 20px;
            font-family: $font-kantumruy;
            background-color: rgb(250, 240, 240);

            .quotation-status {
              width: 80px;
              text-align: center;
              font-family: $font-kantumruy;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 10px;
              padding: 4px 0px;
              border-radius: 4px;
              color: #fff;
            }
            .invoice-status {
              width: 80px;
              text-align: center;
              font-family: $font-kantumruy;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 10px;
              padding: 4px 0px;
              border-radius: 4px;
              color: #fff;
            }
          }

          .body-cell-end {
            border: none;
            font-size: $font-size-body;
            padding: 6px 20px 6px 0px;
            border-radius: 0px 6px 6px 0px;
          }
          .body-cell-end-void {
            border: none;
            font-size: $font-size-body;
            padding: 6px 20px 6px 0px;
            border-radius: 0px 6px 6px 0px;
            background-color: rgb(250, 240, 240);
          }
        }
      }
    }
  }
}
