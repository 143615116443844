@import "../themes.scss";

.dialog-container {
  .css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag {
    background-color: $primary-color;
    color: #fff;
    border-radius: 10px !important;
  }

  .css-eem9g9-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #fff !important;
  }

  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .title-parent-meeting {
    font-size: $font-size-subtitle;
    font-family: $font-kantumruy;
    color: $primary-color;
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-kantumruy;
    text-transform: uppercase;
  }

  .close-icon {
    color: red;
  }

  .certified-image {
    width: 400px;
    height: auto;
  }

  .view-certified-image {
    width: 600px;
    height: auto;
  }

  .btn-upload {
    padding: 6px 20px;
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    background-color: $primary-color !important;
    color: #fff;
    text-transform: none;
  }

  .avater-image {
    width: 200px;
    height: auto;
    border-radius: 10px;
    // object-fit: cover !important;
  }

  .student-image {
    width: 150px;
    height: 150px;
  }

  .image-title {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    color: $primary-color;
    text-align: center;
  }

  .image-title-error {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    color: $background-delete;
    text-align: center;
  }

  .field-title-bold {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    font-weight: bold; 
    color: $primary-color;
  }
  .field-title-bold-enrollment {
    font-family: $font-bayon;
    font-size: $font-size-title;
    color: $primary-color;
  }

  .field-title {
    font-family: $font-kantumruy;
    font-size: $font-size-body; 
    color: $primary-color;
    font-weight: bold;
  }

  .field-item {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
  }

  // ========================== setup exam update subject ===========================
  .department-title {
    width: 300px;
    padding: 4px 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  }
  .btn-add {
    background-color: $primary-color !important;
    font-weight: bold;
    padding: 4px 10px;
    color: #fff;
    border-radius: 5px;

    .add-icon {
      font-size: 20px;
    }
  }
  .access-delete-container {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background-color: #fff !important;
    border: 1px solid $background-delete;

    .delete-icon {
      color: $background-delete;
      margin-top: 2px;
      // margin-bottom: 3px;
      font-size: 16px !important;
    }
  }

  .table-head {
    height: 40px !important;
    border: none;

    .thead-title-start {
      padding: 10px;
      font-family: $font-kantumruy;
      font-size: $font-size-body;
      font-weight: bold;
      color: $primary-color;
      border: none;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }

    .thead-title {
      padding: 10px;
      color: $primary-color;
      border: none;
      font-family: $font-kantumruy;
      font-size: $font-size-body;
      font-weight: bold;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }

    .thead-title-end {
      color: $primary-color;
      padding: 10px;
      border: none;
      font-family: $font-kantumruy;
      font-size: $font-size-body;
      font-weight: bold;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }
  }

  .table-list-body {
    // background-color: aquamarine;
    // border-bottom: 2px solid gray !important;
    font-family: $font-kantumruy;
  }

  .subject-select {
    background-color: $primary-color;
    color: #fff;
    border-radius: 8px !important;

    .icon-subject-select {
      color: #fff;

      &:hover {
        color: wheat !important;
      }
    }
  }

  .list-table-cell {
    padding: 8px 10px;
    font-family: $font-size-body;
    font-size: $font-size-body;
    color: $primary-color;
  }

  .list-table-body-cell {
    padding: 10px;
    font-family: $font-size-body;
    font-size: $font-size-body;
  }

  .addlist-icon {
    color: $primary-color;
    font-size: 24px;
  }

  .deletelist-icon {
    color: $background-delete;
    font-size: 24px;
  }

  .viewpw-icon {
    cursor: pointer;
  }

  .btn-create {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 5px;
    font-family: $font-kantumruy;
    box-shadow: none;
  }

  .btn-delete {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $background-delete !important;
    color: #fff;
    border-radius: 5px;
    font-family: $font-kantumruy;
  }

  .icon-button {
    width: 180px;
    height: 180px;
    margin-top: -10px;

    .company-logo {
      width: 100%;
      height: 100%;
    }
  }

  .invoice-title {
    font-size: 18px;
    font-family: $font-khmer-moul;
    font-weight: 200;
    color: $primary-color;
    text-align: center;
  }

  .invoice {
    font-size: 18px;
    font-family: $font-kantumruy;
    font-weight: bold;
    color: $primary-color;
    text-align: center;
    border-bottom: 1px solid $primary-color;
  }

  .date-title {
    font-size: $font-size-body;
    font-family: $font-kantumruy;
    text-align: right;
    color: $primary-color;
  }

  .body-title {
    font-size: $font-size-body;
    font-family: $font-kantumruy;
    color: $primary-color;
  }

  .tab-container {
    border-collapse: collapse;
    // width: 100%;
    border: 1px solid $primary-color;

    .thead-table {
      border: 1px solid $primary-color;
      font-size: 14px;
      font-family: $font-kantumruy;
    }

    .body-cell {
      border: 1px solid $primary-color;
      text-align: center;
      font-size: 14px;
      font-family: $font-kantumruy;
    }

    .footer-tab {
      border: 1px solid $primary-color;
      text-align: center;
      font-size: 14px;
      font-family: $font-khmer-moul;
    }
  }

  .footer-invoice {
    font-size: $font-size-body;
    font-family: $font-khmer-moul;
    color: $primary-color;
    text-align: center;
  }

  .btn-print {
    background-color: $primary-color !important;
    font-size: $font-size-subtitle;
    color: #fff;
    text-align: center;
  }

  //===============================enrollment=============================================
  //======active =====
  .active-icon {
    background-color: #09ff00;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 7px !important;
  }

  .not-active-icon {
    background-color: #ff0000;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 7px;
    // color: #653516;
  }
  .text-active {
    font-family: $font-kantumruy;
    font-size: 14px;
    // color: $primary-color;
    color: #005bfa;
    font-weight: bold;
  }
  .text-inactive {
    font-family: $font-kantumruy;
    font-size: 14px;
    // color: $primary-color;
    color: #b6b4b4;
    font-weight: bold;
  }
}
