/* <style> */
@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
/* </style> */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: GothicBoldRegular;
  src: url("./Assets/font/GothicBoldRegular.ttf");
}

@font-face {
  font-family: Century;
  src: url("./Assets/font/Century.ttf");
}

@font-face {
  font-family: KhmerOsMuol;
  src: url("./Assets/font/KhmerOSmuollight.ttf");
}

@font-face {
  font-family: Siemreap;
  src: url("./Assets/font/KhmerOSsiemreap.ttf");
}

@font-face {
  font-family: Bayon;
  src: url("./Assets/font/Bayon.ttf");
}

@font-face {
  font-family: BalooPaaji;
  src: url("./Assets/font/BalooPaaji.ttf");
}

@font-face {
  font-family: WingDing;
  src: url("./Assets//font/wingding.ttf");
}

@font-face {
  font-family: Kantumruy;
  src: url("./Assets//font/Kantumruy.ttf");
}

/*   ============================================================= Invocie details for print ==================================================== */
.print-invoice-container {
  background-color: #fff;
  width: 100%;
  padding: 40px 30px 20px 30px;
}

.invoice-logo-image {
  margin-top: 10px;
  width: 100px !important;
  height: auto;
}

.invoice-title-Report {
  text-align: center;
  font-weight: 100 !important;
  font-family: "KhmerOsMuol" !important;
  font-size: 10px !important;
  letter-spacing: normal !important;
  color: #000;
}

.invoice-title-En {
  font-size: 10px !important;
  text-align: center;
  font-family: "GothicBoldRegular" !important;
  font-weight: bold !important;
  padding: 2px 0px 2px 0px;
  color: #000;
}

.invoice-tackteng-font {
  display: flex;
  justify-content: center;
}

.invoice-image-tackteng {
  width: 110px;
}

.invoice-container {
  margin: 0;
  padding: 10px !important;
}

.invoice-main-header {
  padding: 13px 0px 0px 10px !important;
  justify-content: right !important;
}

.invoice-text-head-title-bold {
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Kantumruy Pro", sans-serif !important;
  /* transform: scaleY(1.4); */
}

.invoice-text-head-title {
  font-size: 10px !important;
  font-family: "Kantumruy Pro", sans-serif !important;
}

.invoice-text-head-title-en-bold {
  font-size: 10px !important;
  font-family: "Century Gothic" !important;
  font-weight: bold !important;
}

.invoice-title-kh {
  text-align: center;
  font-size: 18px !important;
  font-family: "Kantumruy Pro", sans-serif !important;
  /* transform: scaleY(1.4); */
  font-weight: bold !important;
}

.invoice-title-en {
  text-align: center;
  font-size: 12px !important;
  font-family: "Century Gothic";
  /* transform: scaleY(1.4); */
  font-weight: bold !important;
}

.invoice-title {
  text-align: center;
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Kantumruy Pro", sans-serif !important;
  letter-spacing: 1px;
}

.invoice-text-head-title-total {
  justify-content: left !important;
  font-size: 10px !important;
  font-family: "Kantumruy Pro", sans-serif !important;
  font-weight: bold !important;
}

.invoice-text {
  font-family: "Century Gothic";
  font-size: 18px;
  font-weight: bold;
}

.header-invoice {
  margin: 0px 4px !important;
}

.header-text-invioce {
  font-size: 9px !important;
  font-family: "Kantumruy Pro", sans-serif !important;
  font-weight: bold !important;
}

.invoice-body-text {
  color: #9e9e9e;
  font-size: 13px !important;
  font-weight: 500;
}

.dividers-footer {
  border-bottom-width: 2px;
  font-weight: bold;
}

.invoice-footer-divider {
  border-color: #000 !important;
  font-weight: bold !important;
  border-bottom-width: 1px !important;
}

.invoice-footer-title {
  font-size: 12px;
  font-family: "Kantumruy Pro", sans-serif !important;
  font-weight: bold !important;
}

.invoice-head-text {
  padding: 0px 10px !important;
  font-size: 15px !important;
  color: #424242 !important;
  font-weight: bold !important;
}

.invoice-title-text {
  padding: 0px 10px !important;
  font-size: 14px !important;
  color: #616161 !important;
}

.invoice-check {
  width: 14px !important;
  height: 14px !important;
  border-radius: 4px;
  border: 1px solid gray;
}

.invoice-des-en {
  font-family: "Kantumruy Pro", sans-serif !important;
  font-size: 9px !important;
}
.invoice-des {
  font-family: "Kantumruy Pro", sans-serif !important;
  font-size: 9px !important;
}

.invoice-total {
  font-family: "Kantumruy Pro", sans-serif !important;
  font-size: 10px !important;
}

.invoice-des-in-des {
  font-family: "Kantumruy Pro", sans-serif !important;
  font-size: 9px !important;
}
