@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

// -----------Color
$primary-color: #0f81c2;
$primary-background: #eef0f3;
$primary-menu: #c7cacf;
$background-delete: #f84b4b;
$background-dollar: #ff8204;
$gray-font-color: gray;

//font
$font-bayon: "Bayon";
$font-century: "Century Gothic";
$font-siemreap: "Siemreap";
$font-khmer-moul: "KhmerOsMuol";
$font-kantumruy: "Kantumruy Pro", sans-serif;

//font-size
$font-size-title: 19px;
$font-size-subtitle: 17px;
$font-size-body: 15px;
// Login Page

@mixin desktop {
  @media (min-width: #{1025px}) and (max-width: #{1650px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{769px}) and (max-width: #{1024px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{426px}) and (max-width: #{768px}) {
    @content;
  }
}

@mixin mobile-large {
  @media (min-width: #{426px}) and (max-width: #{600px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{377px}) and (max-width: #{425px}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width : #{0px}) and (max-width: #{376px}) {
    @content;
  }
}
