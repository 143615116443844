@import "../../themes.scss";

.print-contract {
  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-kantumruy;
    text-transform: uppercase;
  }

  .close-icon {
    color: red;
  }
}

.contract-logo-image {
  margin-top: 10px;
  width: 140px !important;
  height: auto;
}

.contract-logo-title {
  margin-top: -18px !important;
  text-align: center !important;
  color: $primary-color !important;
  font-family: $font-siemreap !important;
  font-size: 13px !important;
}

.contract-title-Report {
  text-align: center;
  font-weight: 100 !important;
  font-family: "KhmerOsMuol" !important;
  font-size: 15px !important;
  letter-spacing: normal !important;
  color: #000;
}

.contract-title-En {
  font-size: 15px !important;
  text-align: center;
  font-family: "GothicBoldRegular" !important;
  font-weight: bold !important;
  padding: 2px 0px 2px 0px;
  color: #000;
}

.contract-image-tackteng {
  width: 180px;
}

.contract-body-des-bold {
  font-weight: bold !important;
  font-family: $font-siemreap !important;
  font-size: 15px !important;
}

.contract-body-des-bold-center {
  text-align: center;
  font-weight: bold !important;
  font-family: $font-siemreap !important;
  font-size: 15px !important;
}

.contract-body-des-moul {
  font-weight: 100 !important;
  font-family: "KhmerOsMuol" !important;
  font-size: 15px !important;
}

.contract-body-des {
  font-weight: 100 !important;
  font-family: $font-siemreap !important;
  font-size: 15px !important;
}

// =================================================== Footer style ===============================================

div.contract-page-footer {
  text-align: center;
  height: 50px;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

table.print-contract-container {
  page-break-after: always;
  width: 100%;
}

thead.contract-header {
  display: table-header-group;
}

tfoot.contract-footer {
  display: table-footer-group;
}

div.contract-footer-info,
div.contract-page-footer {
  display: none;
  height: 50px;
}

@media print {
  @page {
    // size: A4;
    margin-top: 4mm !important;
  }

  .watermark {
    display: block;
    counter-increment: page;
    position: fixed;
  }

  div.contract-page-footer,
  div.contract-footer-info {
    display: block;
  }
}

.divider {
  border-bottom: 3px solid #97badb !important;
}
.page-footer-text-main {
  line-height: 25px;
  font-family: $font-kantumruy;
  letter-spacing: normal !important;
  font-size: 11px !important;
  font-weight: 500;
  color: #858af3;
  text-align: center;
}
