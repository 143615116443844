@import "../../themes.scss";

// @media print {
.page-quotation {
  .page-header {
    height: 40px;
    width: 100%;

    .invoice-slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .invoice-page-title {
      font-family: "Century Gothic";
      color: $primary-color;
      font-size: 19px;
      font-weight: bold !important;
    }
    .invoice-page-title-kh {
      font-family: "Siemreap";
      color: $primary-color;
      font-size: 19px;
      font-weight: bold !important;
    }

    .invoice-slash-title {
      font-family: "Century Gothic";
      color: $primary-color;
      font-size: 19px;
      font-weight: bold;
    }

    .invoice-page-link-title {
      font-family: "Century Gothic";
      color: $primary-color;
      font-size: 15px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .btn-print {
    background-color: $primary-color !important;
    border-radius: 10px;
    color: #fff;
    padding: 8px 20px;
  }

  .box-print-invoice-container {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }
}
