@import "../themes.scss";

.update-icon {
  color: $primary-color;
  font-size: 22px !important;
}

.status-icon {
  color: #f99a2d !important;
  font-size: 26px !important;
}

.delete-icon {
  color: $background-delete;
  font-size: 26px !important;
}

.update-title {
  font-family: $font-kantumruy;
  font-size: $font-size-body !important;
}

.reset-container {
  width: 23px;
  height: 23px;
  border-radius: 8px;
  background-color: green !important;

  .reset-icon {
    color: #fff !important;
    margin-bottom: 4px;
    font-size: 14px !important;
  }

  // &:hover {
  //   background-color: $background-dollar !important;
  // }
}
.update-container {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  border: 1px solid $primary-color !important;

  .update-icon {
    color: $primary-color !important;
    margin-bottom: 4px;
    font-size: 14px !important;
  }

  .doc-icon {
    color: $primary-color !important;
    margin-bottom: 2px;
    font-size: 18px !important;
  }
}

.status-container {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  border: 1px solid $background-dollar !important;

  .status-icon {
    color: $background-dollar !important;
    font-size: 16px !important;
    margin-bottom: 3px;
  }
}

.main-action {
  cursor: pointer !important;
  padding-right: 14px;

  .class-update-container {
    width: 23px;
    height: 23px;
    border-radius: 8px;
    background-color: $background-dollar !important;

    .update-icon {
      color: #fff;
      margin-bottom: 4px;
      font-size: 14px !important;
    }
  }
  .delete-container {
    width: 26px;
    height: 26px;

    .delete-icon {
      color: $background-delete;
      margin-bottom: 4px;
    }
  }

  .class-title {
    color: $primary-color;
  }

  &:hover {
    background-color: rgb(240, 240, 240) !important;
  }
}
.save-container {
  width: 22px;
  height: 22px;
  .save-icon {
    width: 22px;
    color: $primary-color;
  }
}

.delete-container {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  // background-color: $background-delete !important;
  border: 1px solid $background-delete !important;

  .delete-icon {
    color: $background-delete;
    font-size: 15px !important;
    margin-bottom: 4px;
  }
}

.dot-container {
  width: 20px;
  height: 20px;

  .dot-icon {
    color: $primary-color !important;
    margin-bottom: 5px !important;
  }
}

//========= allow class menu dropdown =====================
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}
.menu-test {
  padding: 5px 7px 5px 0px !important;
  .icon-class {
    color: #e0af0e;
  }
  .text-class {
    font-family: $font-kantumruy;
    .css-3r39km-MuiTypography-root {
      font-size: 14px !important;
      color: $primary-color;
      margin-right: 5px;
    }
  }
}

.define-container {
  width: 20px;
  height: 20px;

  .define-icon {
    color: green;
    margin-bottom: 4px;
    width: 20px !important;
    height: 20px !important;
  }
}
.btn-void {
  height: 28px !important;
  border-radius: 5px !important;
  background-color: $background-delete !important;
  color: #fff !important;
  padding: 2px 10px !important;
  font-family: $font-siemreap;
  font-size: 13px;
}

// .update-title {
//   font-family: $font-kantumruy;
//   font-size: $font-size-body !important;
// }

.label-check {
  font-size: $font-size-body;
  font-family: $font-siemreap;
}
