@import "../themes.scss";

.project-container {
  .slash {
    height: 40px;
    width: 4px;
    background-color: $primary-color;
  }

  .page-title {
    font-family: $font-kantumruy;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold !important;
  }

  .page-header {
    height: 40px;
    width: 100%;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }
    .page-title-kh {
      font-family: $font-siemreap;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy;
      color: black;
      font-size: $font-size-body;
    }
    .breadcrumbs-title-kh {
      font-family: $font-siemreap;
      color: black;
      font-size: $font-size-body;
    }

    .slash-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold;
    }

    .page-link-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .search-title {
    font-family: $font-kantumruy;
    color: $primary-color;
    font-size: $font-size-body;
    // margin-bottom: 5px;
  }

  //Card
  .card-container {
    height: 220px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #7070706e;
    border-radius: 10px;
    .status-card {
      // padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
      // font-size: $font-size-body;
      font-size: 13px;
      font-family: $font-kantumruy;
      margin-top: 10px;
    }
    .project-name {
      font-size: $font-size-subtitle;
      font-family: $font-kantumruy;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .total-price {
      font-family: $font-kantumruy;
      font-size: 13px;
      font-weight: bold;
      color: #ea2877;
    }
    .total-payment {
      font-family: $font-kantumruy;
      font-size: 13px;
      font-weight: bold;
      color: $primary-color;
    }
    .date {
      font-family: $font-kantumruy;
      font-size: 12px;
      font-weight: bold;
      color: #838383;
    }
    .icon-date {
      font-family: $font-kantumruy;
      font-weight: bold;
      color: #838383;
    }
    .line {
      height: 16px;
      width: 1px;
      background-color: #838383;
    }
    .more-icon {
      height: 25px;
      width: 25px;
      color: #838383;
    }
    .invoice-des {
      font-size: 16px !important;
      font-family: $font-kantumruy;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .customer-title {
      font-family: $font-kantumruy;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-color;
    }
    .customer-label {
      font-family: $font-kantumruy;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: gray;
    }

    .customer-label-status {
      font-family: $font-kantumruy;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy;
    font-weight: bold;
    color: $primary-color;
    border-color: #fff;

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: $primary-color !important;
      font-family: $font-kantumruy;
      border-radius: 6px !important;
      border-color: #fff;
    }

    .MuiInputLabel-root.Mui-focused {
      color: $primary-color;
      font-family: $font-kantumruy;
      border-radius: 6px;
      border-color: #fff;
    }

    .MuiOutlinedInput-input {
      color: $primary-color;
      font-family: $font-kantumruy;
      border-color: #fff;
    }

    .supplier-detail-box {
      justify-content: space-between;
    }
  }

  .select-role {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-kantumruy;
    font-weight: bold;
    color: $primary-color;
    border-color: #fff;

    .MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-kantumruy;
      border-radius: 6px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
      border-color: #fff;
    }

    .MuiInputLabel-root.Mui-focused {
      font-family: $font-kantumruy;
      border-radius: 6px;
      color: $primary-color;
      border-color: #fff;
    }
  }

  .btn-create {
    font-family: $font-kantumruy;
    font-size: $font-size-body;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 6px 30px;
    height: 100%;
    margin-top: 25px;

    .icon-add {
      width: 18px;
      height: 18px;
    }
  }

  .body-container {
    margin-top: 20px;
    width: 100%;

    .card-container {
      padding: 6px 6px 10px 15px !important;
      text-transform: none !important;
      text-align: left;
      background-color: #fff !important;
      border-radius: 10px;
      border: 1px solid rgb(197, 196, 196);
      height: 220px;

      .invoice-num {
        margin-top: 10px;
        font-family: $font-kantumruy;
        font-size: 14px;
        font-weight: bold;
        color: #f99a2d;
      }
      .project-name {
        font-family: $font-kantumruy;
        font-size: $font-size-subtitle;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .invoice-usd-icon {
        color: $primary-color;
        font-size: 20px;
      }
      .invoice-usd-name {
        font-family: $font-kantumruy;
        font-size: 14px;
        font-weight: bold;
        color: $primary-color;
      }
      .invoice-usd-price {
        font-family: $font-kantumruy;
        font-size: 14px;
        font-weight: bold;
        color: #ea2877;
      }
      .invoice-des {
        font-family: $font-kantumruy;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .invoice-bill-to {
        font-family: $font-kantumruy;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: $primary-color;
      }

      .invoice-bill-to-item {
        font-family: $font-kantumruy;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        // color: gray;
      }
    }
  }
}

//============================== PROJECT DETAIL ==============================================
.project-detail-container {
  .slash {
    height: 40px;
    width: 4px;
    background-color: $primary-color;
  }

  .page-title {
    font-family: $font-kantumruy;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold !important;
  }

  .page-header {
    height: 40px;
    width: 100%;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }
    .page-title-kh {
      font-family: $font-siemreap;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy;
      color: black;
      font-size: $font-size-body;
    }
    .breadcrumbs-title-kh {
      font-family: $font-siemreap;
      color: black;
      font-size: $font-size-body;
    }

    .slash-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold;
    }

    .page-link-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  //Card
  .card-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #7070706e;
    border-radius: 10px;

    .date {
      font-family: $font-kantumruy;
      font-size: 11px;
      font-weight: bold;
      color: #838383;
    }
    .icon-date {
      font-family: $font-kantumruy;
      font-weight: bold;
      color: #838383;
    }

    .project-des {
      font-family: $font-kantumruy;
      color: #6862c2;
    }
    .customer-label {
      font-family: $font-kantumruy;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: gray;
    }
  }

  .btn-create {
    font-family: $font-siemreap;
    font-size: $font-siemreap;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    // height: 100%;
    margin-top: 25px;

    .icon-add {
      width: 18px;
      height: 18px;
    }
  }
}
