@import "../themes.scss";

.setting-page {
  .page-header {
    height: 40px;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy;
      color: black;
      font-size: $font-size-body;
    }

    .page-link-title {
      font-family: $font-kantumruy;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .setting-page-container {
    background-color: #fff;
    padding: 0px 20px;
    border-radius: 10px;
    height: 110px;

    .image {
      width: 40px;
      height: 40px;
    }

    .page-title {
      font-family: $font-kantumruy;
      font-size: $font-size-subtitle;
      color: $primary-color;
      font-weight: bold;
    }

    .page-description {
      width: 100%;
      font-family: $font-kantumruy;
      font-size: $font-size-body;
      color: $primary-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
      color: #191919;
    }
  }
}
