@import "../themes.scss";

// .main {
//   position: relative;
//   width: 100%;
//   background-color: $primary-background;
// }

// .inner {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background-color: $primary-background;
//   display: table;
//   overflow-x: auto;
//   white-space: nowrap;

//   @include mobile-small {
//     width: 320px !important;
//   }
//   @include mobile {
//     width: 480px !important;
//   }
//   @include mobile-large {
//     width: 550px !important;
//   }
//   @include tablet {
//     width: 768px !important;
//   }
// }

.layout-container {
  margin-top: 60px;
  width: 100%;
  height: 100%;
  .outlet-container {
    background-repeat: no-repeat;
    background-image: url("../Assets/angkor-picture.png");
    background-attachment: fixed;
    height: 90vh;
    background-position: 70% 22%;
    background-size: 74%;
 
    @include desktop {
      background-size: 80%;
      background-position: 60% 18%;
    }
    @include laptop {
      background-size: 100%;
      background-position: 100% 14%;
    }
    @include tablet {
      background-size: 120%;
      background-position: 60% 12%;
    }
    @include mobile-large {
      background-size: 140%;
      background-position: 50% 11%;
    }
  }
}

.backdrop-style {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(221, 233, 243, 1) 100%
  );

  .background-image {
    // background-image: url("../Assets/angkor-picture.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 50%;
    position: fixed;
    opacity: 0.1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .system-title {
    margin-top: 10px;
    margin-bottom: 20px;
    color: $primary-background;
    font-size: 20px;
    font-weight: bold;
  }

  .power-title {
    font-weight: bold;
    color: $primary-background;
  }
}
